<ess-shared-ui-dialogs-custom [hasFooter]="false" [header]="'Metrics glossary'" (closed)="_dialogRef.close()">
  <div class="flex flex-col h-full">
    <ess-shared-ui-controls-input-control
      [formControl]="_searchControl"
      [placeholder]="'Search for metric'"
      [prefixIcon]="'search'"
      [color]="'grey'"
      [allowReset]="true"
    ></ess-shared-ui-controls-input-control>

    <mat-accordion class="shared-accordion overflow-y-auto" [multi]="true">
      @for(metric of _displayedDefinitions(); track metric) {

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="text-label" [innerHTML]="metric.metricName | highlight : _searchControl.value"></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        @if(metric.tableContext) {
        <p class="text-xs-bold text-base-note-100 uppercase mb-1">In table context:</p>
        <p class="text-sm mb-2.5" [innerHTML]="metric.tableContext | highlight : _searchControl.value"></p>
        } @if(metric.chartContext) {
        <p class="text-xs-bold text-base-note-100 uppercase mb-1">In chart context:</p>
        <p class="text-sm" [innerHTML]="metric.chartContext | highlight : _searchControl.value"></p>
        }
      </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
</ess-shared-ui-dialogs-custom>
