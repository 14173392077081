import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ISRoutesConfig } from '@ess/integrated-search/shared/utils';

@Component({
  selector: 'is-integrated-search-shared-feature-footer',
  standalone: true,
  imports: [RouterModule],
  template: `
    <div
      class="flex flex-wrap justify-end items-center gap-1 text-xs text-neutral-variant-600 text-right whitespace-nowrap"
    >
      <span>Integrated Search Dashboard 2024.</span>
      <span>All rights reserved.</span>
      <a class="underline text-base-primary" [routerLink]="ISRoutesConfig.privacyPolicy()" [target]="'_blank'">
        Privacy policy
      </a>
      <a class="underline text-base-primary" [routerLink]="ISRoutesConfig.licenses()" [target]="'_blank'">Licenses</a>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegratedSearchSharedFeatureFooterComponent {
  protected readonly ISRoutesConfig = ISRoutesConfig;
}
