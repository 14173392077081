import { ChangeDetectionStrategy, Component, inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { filter, map } from 'rxjs';

import { IS_METRICS_GLOSSARY, ISMetricsDefinition, ISMetricsGlossary } from '@ess/integrated-search/shared/utils';
import { SharedUiControlsInputControlComponent } from '@ess/shared/ui/controls';
import { SharedUiDialogsCustomComponent } from '@ess/shared/ui/dialogs';
import { HightlightPipe } from '@ess/shared/utils/pipes';

@Component({
  standalone: true,
  imports: [
    SharedUiDialogsCustomComponent,
    SharedUiControlsInputControlComponent,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    HightlightPipe,
  ],
  templateUrl: './integrated-search-shared-feature-metrics-glossary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegratedSearchSharedFeatureMetricsGlossaryComponent {
  protected readonly _dialogRef = inject(MatDialogRef<IntegratedSearchSharedFeatureMetricsGlossaryComponent>);
  protected readonly _searchControl = new FormControl<string | null>(null);
  protected readonly _metricDefinitions = IS_METRICS_GLOSSARY.sort((a, b) => a.metricName.localeCompare(b.metricName));

  protected readonly _displayedDefinitions: Signal<ISMetricsGlossary> = toSignal(
    this._searchControl.valueChanges.pipe(
      filter((query: string | null) => !query || query?.length >= 2),
      map((query: string | null) => {
        return query
          ? this._metricDefinitions.filter((definition: ISMetricsDefinition) =>
              Object.keys(definition).some((key) =>
                definition[key as keyof ISMetricsDefinition]
                  ?.toLowerCase()
                  .replace(/\n/g, ' ')
                  .includes(query.toLowerCase()),
              ),
            )
          : this._metricDefinitions;
      }),
    ),
    { initialValue: this._metricDefinitions },
  );
}
