import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class IntegratedSearchSharedFeatureTitleService {
  private readonly SUFFIX = 'Integrated Search Dashboard';
  private readonly DIVIDER = ' - ';

  private readonly __title = inject(Title);

  setTitle(routeTitle?: string, clientName?: string, projectName?: string): void {
    this.__title.setTitle(this.__buildTitle(clientName, projectName, routeTitle));
  }

  updateTitle(name: string): void {
    const currentTitle = this.__title.getTitle();
    this.__title.setTitle([name.trim(), currentTitle].join(this.DIVIDER));
  }

  private __buildTitle(...parts: (string | undefined)[]): string {
    const fullTitle = [this.SUFFIX];
    parts.forEach((part) => part && fullTitle.unshift(part.trim()));
    return fullTitle.join(this.DIVIDER);
  }
}
