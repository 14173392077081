import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { createStore, select, withProps } from '@ngneat/elf';
import { filter, map, Observable } from 'rxjs';

import { ISMenu, ISStoreEnum } from '@ess/integrated-search/shared/utils';
import { SharedStore } from '@ess/shared/utils/models';

interface StoreProps {
  menu: ISMenu;
}

type Store = SharedStore<StoreProps>;

@Injectable()
export class IntegratedSearchSharedFeatureMenuRepository {
  get menu$(): Observable<ISMenu> {
    return this.__store.pipe(select((state) => state.menu));
  }

  private readonly __store: Store;

  constructor(private readonly __router: Router, private readonly __route: ActivatedRoute) {
    this.__store = this.__createStore();
    this.__listenRouteChange();
  }

  private __createStore(): Store {
    return createStore(
      { name: ISStoreEnum.MENU },
      withProps<StoreProps>({
        menu: [],
      }),
    );
  }

  private __listenRouteChange(): void {
    this.__router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => this.__getMenu()),
      )
      .subscribe((menu: ISMenu) => this.__store.update((state) => ({ ...state, menu })));
  }

  private __getMenu(): ISMenu {
    let snapshot: ActivatedRouteSnapshot | null = this.__route.snapshot;
    let menu: ISMenu = [];
    do {
      menu = snapshot.data['menu'] ?? menu;
      snapshot = snapshot.firstChild;
    } while (snapshot);
    return menu;
  }
}
